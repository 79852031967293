"use client";

import { UserContext, useUser } from "@/hooks/useUser";
import { SessionProvider } from "next-auth/react";
import { SWRConfig } from "swr";

type Props = {
    children?: React.ReactNode;
};

export const ClientProviders = ({ children }: Props) => {
    return <SWRConfig
        value={{
            // refreshInterval: 3000,
            fetcher: (resource: RequestInfo | URL, init?: RequestInit | undefined) => fetch(resource, init)
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        console.error('Api error response: ', res.error)
                    }
                    return res.results
                })
                .catch((err) => console.log('API error: ', err.response))
        }}
    >
        <SessionProvider>
            <InnerProviders>
                {children}
            </InnerProviders>
        </SessionProvider>
    </SWRConfig>;
};


const InnerProviders = ({ children }: Props) => {
    const userProps = useUser()
    return (
        <UserContext.Provider value={userProps}>
            {children}
        </UserContext.Provider>
    )
};
