"use client";
import React, { useState } from "react";
import { useServerInsertedHTML } from "next/navigation";
import { getCssText } from '../stitches.config';
import { fixStitchesCssNumbers, getCssText as dsGet } from '@planda/design-system';

export default function StitchesRegistry({ children }: { children: React.ReactNode }) {
    const [isRendered, setIsRendered] = useState(false);

    useServerInsertedHTML(() => {
        if (!isRendered) {
            setIsRendered(true);
            return <style id="stitches" dangerouslySetInnerHTML={{ __html:  fixStitchesCssNumbers(getCssText(), dsGet()) }} />;
        }
    });

    return <>{children}</>;
}