import { createStitches, PropertyValue } from '@stitches/react';
import { extend, merge } from 'lodash';
import { config, createAllThemes, globalStyles, themes, toProviderThemes } from '@planda/design-system';
// { blackA, blue, blueDark, crimson, crimsonA, crimsonDark, crimsonDarkA, gray, grayDark, green, greenDark, mauve, mauveDark, pink, pinkDark, red, redDark, sage, sageDark, teal, tealDark, whiteA, yellow, yellowDark }
// to understand color scale: https://www.radix-ui.com/docs/colors/palette-composition/understanding-the-scale

export const {
  styled,
  getCssText,
  createTheme,
  keyframes,
  globalCss
} = createStitches(merge({}, { ...config }, {
  theme: {
    colors: {
      focus: '$hiContrast',
      test: 'red',
      border: '$borderWidths$medium solid $primary12',
      borderThin: '$borderWidths$thin solid $primary12',
      borderThick: '$borderWidths$thick solid $primary12',
      borderFocus: `0 0 0 2px $colors$focus`,
    },
    fonts: {
      system: `"Monaco", "Lucida Console", monospace`
    },
  }
}))

// #endregion

const createdThemes = createAllThemes(themes, createTheme)

export const providerThemes = toProviderThemes(createdThemes)


const GlobalStylesApply = globalCss(merge(globalStyles, {
  'h1, h2, h3, h4, h5, h6': {
    color: '$hiContrast',
    margin: 0,
    display: "block",
    fontWeight: "bold"
  },
}))
//we can declare the styles here or in pages/_app.tsx
GlobalStylesApply();