'use client'
import { MEETING_ID_SIZE } from "@/constants"
import useSWR from "@/lib/swr"
import { MeetParticipant, MeetUser } from "@/types"
import { useForageItem } from "@planda/design-system"
import { isString } from "lodash"
import { useSession } from "next-auth/react"
import { usePathname, useSearchParams } from "next/navigation"
import { createContext, useContext } from "react"

export interface UserProps {
    name: string,
    setParticipantName: (name: string) => void
}

export const UserContext = createContext<UserProps>({ name: '', setParticipantName: () => { } })

export function useGlobalUser() {
    const props = useContext(UserContext)

    const searchParams = useSearchParams()

    return { ...props, name: searchParams.get('name') || props.name }
}

export const useUser = () => {
    const pathname = usePathname()
    const pathPart = pathname.split('/').at(1)
    const id = pathPart?.length === MEETING_ID_SIZE ? pathPart : null

    const { item: participantName, set } = useForageItem('participantName', isString, '')
    const { data: participant } = useSWR<MeetParticipant>(id ? `/api/${id}/participant` : null, {
        onSuccess: (participant) => {
            participant.name && set(participant.name)
        },
        shouldRetryOnError: false
    })
    const { data: userProfile } = useSWR<MeetUser>(!id ? '/api/user/profile' : null, {
        onSuccess: (profile) => {
            profile.name && set(profile.name)
        },
        shouldRetryOnError: false
    })
    // await fetcher(`/api/${meetingId}/participant`
    // const { data: participantData, isLoading: participantIsLoading } = useSWR<{ participants: MeetParticipant[] }>(id && `/api/${id}/participants`)
    const { data } = useSession()

    return {
        name: data?.user?.name || participantName,
        setParticipantName: set
    }
}
