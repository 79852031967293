'use client'
import useSWR0 from "swr"
import { fetchGet } from "./fetch"
import { useGlobalUser } from "@/hooks/useUser"

const useSWR = <T>(url: string | null, options?: { onSuccess?: (data: T) => void, onError?: (err: any) => void, shouldRetryOnError?: boolean }) => {
    const props = useSWR0(url, fetchGet<T>, { ...options })

    return props
}

export default useSWR

export const useAuthSWR = <T>(meetingId: string, path: string, options?: { onSuccess?: (data: T) => void }) => {
    const { name } = useGlobalUser()
    const props = useSWR0([path, name], ([path, _]) => fetchGet<T>(`/api/${meetingId}/${path}`, name ? { name } : undefined), options)

    return props
}